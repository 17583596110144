import { graphql } from "gatsby"
import Loadable from "@loadable/component"

const LoadableProtocal = Loadable(() => import("../components/Protocol"))
export default LoadableProtocal

export const query = graphql`
  query {
    allMarkdownRemark(filter: { frontmatter: { type: { eq: "protocol" } } }) {
      edges {
        node {
          frontmatter {
            fileName
            channel
            lang
            type
          }
          html
        }
      }
    }
  }
`
